import { CalendarHeaderCell, CalendarHeaderCellProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

function _CalendarHeaderCell({ children, className, ...props }: CalendarHeaderCellProps) {
  return (
    <CalendarHeaderCell
      className={twMerge('text-p6 h-10 w-10 p-0 font-normal', className)}
      {...props}>
      {children}
    </CalendarHeaderCell>
  );
}

export { _CalendarHeaderCell as CalendarHeaderCell };
