import { CalendarGrid, CalendarGridProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

function _CalendarGrid({ children, className, ...props }: CalendarGridProps) {
  return (
    <CalendarGrid className={twMerge('table-fixed', className)} {...props}>
      {children}
    </CalendarGrid>
  );
}

export { _CalendarGrid as CalendarGrid };
