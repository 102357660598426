import { CalendarGridBody, CalendarGridBodyProps } from 'react-aria-components';

function _CalendarGridBody({ children, ...props }: CalendarGridBodyProps) {
  return (
    <CalendarGridBody className="[&_td]:p-0" {...props}>
      {children}
    </CalendarGridBody>
  );
}

export { _CalendarGridBody as CalendarGridBody };
