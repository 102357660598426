import { CalendarDate } from '@internationalized/date';
// eslint-disable-next-line import/no-unresolved
import { RangeValue } from '@react-types/shared';
import React, { useEffect } from 'react';
import { CalendarStateContext } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { Heading } from '../heading/heading';
import Icon from '../icon/icon';
import { Pressable } from '../pressable/pressable';

export const CalendarHeader = React.forwardRef<
  HTMLElement,
  {
    className?: string;
    onChange?: (visibleRange: RangeValue<CalendarDate>) => void;
  }
>(({ className, onChange }, ref) => {
  const state = React.useContext(CalendarStateContext)!;

  useEffect(() => {
    onChange && onChange(state.visibleRange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.visibleRange?.end?.month]);

  return (
    <header className={twMerge('flex items-center justify-between gap-x-4', className)} ref={ref}>
      <Pressable
        className={(bag) => twMerge('', bag.isDisabled && 'text-grey-400 cursor-not-allowed')}
        slot="previous">
        <div className="flex h-10 w-10 items-center justify-center text-lg">
          <Icon name="chevron-left" />
        </div>
      </Pressable>
      <Heading className="text-sm font-medium leading-5" />
      <Pressable
        className={(bag) => twMerge('', bag.isDisabled && 'text-grey-400 cursor-not-allowed')}
        slot="next">
        <div className="flex h-10 w-10 items-center justify-center">
          <Icon name="chevron-right" />
        </div>
      </Pressable>
    </header>
  );
});
